
import { defineComponent, ref, computed } from 'vue';
import { usePage, useEventsStatistics } from '@/composables';
import { get, map, maxBy } from 'lodash';
import dayjs from 'dayjs';
import { DEFAULT_PAGE_SIZE, exportEventsStatistics } from '@/services/api';
import download from 'downloadjs';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {},
  setup() {
    const eventId = useRoute().params.id as string;
    const eventName = useRoute().params.name as string;
    const eventDateRange = useRoute().params.dateRange as string;
    const filterDateRange = ref<Date[]>(null);

    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const startDate = ref<string>(null);
    const endDate = ref<string>(null);

    const { data, isLoading, isFetching } = useEventsStatistics({ page, pageSize, startDate, endDate }, { id: eventId });

    const maxClickCount = computed(() => maxBy(map((get(data.value, 'data', [])), 'clickCounts'), 'length'));

    const handleDateChange = () => {
      if (!filterDateRange.value) {
        startDate.value = null;
        endDate.value = null;
        return;
      }
      startDate.value = dayjs(filterDateRange.value[0]).startOf('day').toISOString();
      endDate.value = dayjs(filterDateRange.value[1]).endOf('day').toISOString();
    };

    const handleExcelExport = async() => {
      const data = await exportEventsStatistics({
        query: {
          startDate: startDate.value,
          endDate: endDate.value
        },
        id: eventId
      });
      download(data, `${eventName}活動頁數據${eventDateRange.replace(/\s/g, '')}.csv`, 'text/csv');
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      filterDateRange,
      setPageQuery,
      handleDateChange,
      handleExcelExport,
      maxClickCount,
      get
    };
  }
});
