import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aba42b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topNav" }
const _hoisted_2 = { class: "search-box" }
const _hoisted_3 = { class: "datePicker" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_SearchBox = _resolveComponent("SearchBox")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_main = _resolveComponent("el-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.statisticsDateRange,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statisticsDateRange) = $event)),
              type: "datetimerange",
              "range-separator": "-",
              "start-placeholder": "統計開始時間",
              "end-placeholder": "統計結束時間",
              "disabled-date": _ctx.setDisableDate,
              onChange: _ctx.handleDateChange
            }, null, 8, ["modelValue", "disabled-date", "onChange"])
          ]),
          _createVNode(_component_SearchBox, {
            placeholder: "項次/標題",
            clickSearch: (_keyword) => _ctx.keyword = _keyword,
            keyword: _ctx.keyword
          }, null, 8, ["clickSearch", "keyword"]),
          _createVNode(_component_Filter, {
            options: _ctx.filterOptions,
            onChange: _ctx.handleFilterChange
          }, null, 8, ["options", "onChange"])
        ])
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _ctx.data?.data,
        border: "",
        fit: "",
        "highlight-current-row": "",
        style: {"width":"100%"},
        "empty-text": "暫無資料",
        onSortChange: _ctx.handleSort
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "項次",
            prop: "id",
            sortable: "custom",
            width: "80"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.id), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "廣告標題",
            width: "180"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "廣告版位",
            width: "150"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(_ctx.getPositionsTranslate(row.position)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "上架場館",
            width: "250"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedBlocksTranslate(row.blocks) || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "廣告圖檔",
            width: "150"
          }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_el_image, {
                src: row?.coverPhoto?.path,
                "preview-src-list": [row?.coverPhoto?.path],
                "initial-index": 1,
                lazy: ""
              }, null, 8, ["src", "preview-src-list"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "廣告連結",
            "min-width": "350"
          }, {
            default: _withCtx(({row}) => [
              (row.link)
                ? (_openBlock(), _createBlock(_component_el_link, {
                    key: 0,
                    type: "primary",
                    underline: false,
                    href: row.link,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(row.link), 1)
                    ]),
                    _: 2
                  }, 1032, ["href"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, " - "))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "點擊人數",
            width: "100"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.clickHeadcount || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "曝光人數",
            width: "100"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.impressionHeadcount || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "點擊次數",
            width: "100"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.clickCount || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "曝光次數",
            width: "100"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.impressionCount || '-'), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "onSortChange"])), [
        [_directive_loading, _ctx.isLoading || _ctx.isFetching]
      ]),
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.page,
        "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
        "page-size": _ctx.data?.meta?.pageSize,
        layout: "prev, pager, next, jumper",
        total: _ctx.data?.meta?.total || 0,
        onCurrentChange: _ctx.setPageQuery
      }, null, 8, ["currentPage", "page-size", "total", "onCurrentChange"])
    ]),
    _: 1
  }))
}