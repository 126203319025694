
import { defineComponent, ref, computed, toRaw, watch } from 'vue';
import { ElMessage } from 'element-plus';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterOption, FilterType } from '@/components/filter/Index.vue';
import { useBlocks, usePage, useAdvertisementsStatistics, useKeyword } from '@/composables';
import { get, map, merge, keyBy, values, orderBy } from 'lodash';
import { AdvertisementPosition, AdvertisementPositionType, AdvertisementStatusType, Advertisements, Block } from '@/interfaces';
import { DEFAULT_PAGE_SIZE, ResponseError, SortType, getAdvertisementsStatistics } from '@/services/api';
import { formatISOString } from '@/utils/format-time';

const databricksDatasetName: { [key: string]: string } = {
  development: 'mdtv_dev_dataset',
  qa: 'mdtv_qa_dataset',
  staging: 'mdtv_qa_dataset',
  production: 'mdtv_prod_dataset'
};

export default defineComponent({
  components: {
    SearchBox,
    Filter
  },
  setup() {
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const statisticsDateRange = ref(null);
    const defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);
    const status = ref<AdvertisementStatusType>();
    const position = ref<AdvertisementPositionType>();
    const blockIds = ref<number[]>();

    const { data: blocksRes } = useBlocks();

    const blocks = computed(() => get(blocksRes.value, 'data', []) as Block[]);
    const sort = ref<SortType>('desc');
    const startedAt = ref(null);
    const endedAt = ref(null);

    const {
      data,
      isLoading,
      isFetching,
      refetch
    } = useAdvertisementsStatistics({
      page,
      keyword,
      pageSize,
      blockIds,
      position,
      status,
      sort,
      startedAt,
      endedAt
    });

    const filterOptions = computed<FilterOption[]>(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '廣告版位',
          placeholder: '請選擇',
          options: [
            {
              label: '場館 Banner',
              value: AdvertisementPosition.BLOCK_BANNER
            },
            {
              label: '場館浮動按鈕',
              value: AdvertisementPosition.FLOAT_BUTTON
            },
            {
              label: '個人中心 Banner',
              value: AdvertisementPosition.PROFILE_BANNER
            },
            {
              label: 'Header 按鈕',
              value: AdvertisementPosition.HEADER_BUTTON
            }
          ]
        },
        {
          type: FilterType.MULTI_SELECTOR,
          label: '上架場館',
          placeholder: '請選擇',
          options: blocks.value
            .map(block => ({ label: block.name, value: block.id }))
        }
      ];
    });

    const handleFilterChange = (event: any) => {
      position.value = event[0];
      blockIds.value = event[1];
    };

    const getPositionsTranslate = (position: AdvertisementPositionType) => {
      const cases = {
        [AdvertisementPosition.BLOCK_BANNER]: '場館 Banner',
        [AdvertisementPosition.PROFILE_BANNER]: '個人中心 Banner',
        [AdvertisementPosition.FLOAT_BUTTON]: '場館浮動按鈕',
        [AdvertisementPosition.HEADER_BUTTON]: 'Header 按鈕'
      };
      return cases[position] || '未定義';
    };

    const getFormattedBlocksTranslate = (blocks: Block[]) => {
      const resArr = blocks?.map((val) => val.name);
      return resArr?.join('、');
    };

    const handleSort = (e) => {
      const { prop, order } = e;
      if (prop === 'id') {
        const sortTranslate = {
          ascending: 'asc',
          descending: 'desc'
        };
        sort.value = sortTranslate[order];
      }
    };

    const setDisableDate = (time: Date) => {
      return time.getTime() >= Date.now();
    };

    const handleDateChange = () => {
      if (!statisticsDateRange.value) {
        startedAt.value = '';
        endedAt.value = '';

        return;
      }
      startedAt.value = formatISOString(statisticsDateRange.value[0]);
      endedAt.value = formatISOString(statisticsDateRange.value[1]);
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      filterOptions,
      handleFilterChange,
      statisticsDateRange,
      defaultTime,
      setDisableDate,
      handleDateChange,
      keyword,
      getPositionsTranslate,
      getFormattedBlocksTranslate,
      setPageQuery,
      handleSort
    };
  }
});
